import React from 'react';
import {Helmet} from "react-helmet";

import HeadingImage from '../content_components/helpers/HeadingImage';
import { getEnv } from '../helpers/env';
class About extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>عن الفهرست</title>
        </Helmet>
        <HeadingImage images="" title="عن الفهرست" type="year" />
        <p>
          الفهرست هو مشروع يهدف لكسر الحاجز بين القاريء غير المتخصص و التاريخ
          وذلك باستخدام تقنيات الحاسوب الحديثة لعرض التاريخ الإسلامي بشكل تفاعلي وجذاب.
          يمكنك التعرف بشكل أكبر على المشروع بزيارة
          {' '}
          <a href="http://blog.alfehrest.org/">موقع المشروع على الانترنت</a>
          {' '}
          أو بزيارة
          {' '}
          <a href="https://www.facebook.com/alfehrest">صفحة المشروع على الفيسبوك</a>
          {' '}
        </p>
        <h4 className="title">عن المحتوى</h4>
        <p>
          يعي الفريق القائم على الفهرست أن التاريخ مسألة حساسة ولابد من الحرص لمن يريد الكتابة عن التاريخ.
          نحاول دوما أن نتحقق من المعلومات التي ننشرها على الفهرست ولكن قد يجانبنا الصواب أحيانا لذلك
          نرجو ممن لاحظ خطأ أن ينبهنا عليه حتى نقوم بإصلاحه.<br />
          ينبغي أيضا أن نشير أن الفهرست ليس مرجعا تاريخيا بالمعنى الأكاديمي - وإن كنا نأمل أن نصل لتلك المرحلة يوما -
          لذلك لا يغني الفهرست عن مطالعة المراجع التاريخية.
        </p>
        <p className="release">
          رقم الإصدار {getEnv('RELEASE', 'dev')}
        </p>
      </div>
   )
  }
}

export default About;
