import AlfehrestDate from "./AlFehrestDate";
import { PRESENT } from "./date_states";

export const parseImages = images => images

export const getIconGeoJSON = (entity, selectedEntity, date, lookup, dataLookup) => {
  switch(entity) {
    case 'scholar': return getScholarCollection(selectedEntity, date, lookup, dataLookup);
    case 'event': return getEventCollection(selectedEntity, date, lookup, dataLookup);
    case 'place': return getPlaceCollection(selectedEntity, date, lookup, dataLookup);
    case 'monument': return getMonumentCollection(selectedEntity, date, lookup, dataLookup);
  }
}


function getEventCollection(selectedEntity, date, lookup, dataLookup) {
  const points = {};
  const features = [];
  if(!lookup || !lookup.events) {
    return {features, points};
  }
  lookup.events.forEach((eventId) => {
    const event = dataLookup.events[eventId];
    points[`events_${event.id}`] = [event.longitude, event.latitude];
    const iconLookup = {
      'battle': 'event_battle_pin',
      'uprising': 'event_uprising_pin'
    };

    let icon = "event_generic_pin";
    if(event.event_type in iconLookup) {
      icon = iconLookup[event.event_type];
    }

    features.push({
      type: 'Feature',
      properties: {
        id: event.id,
        type: 'event',
        icon: icon,
        selected: selectedEntity.entity === 'events' && Number(selectedEntity.id) === event.id ? 2 : 0,
      },
      geometry: { type: 'Point', coordinates: [event.longitude, event.latitude]}
    });
  });
  return {features, points};
}

function getMonumentCollection(selectedEntity, date, lookup, dataLookup) {
  const points = {};
  const features = [];
  return {features, points};
}

function getPlaceCollection(selectedEntity, date, lookup, dataLookup) {
  const points = {};
  const features = [];
  Object.values(dataLookup['places']).forEach((place) => {
    if(place.place_type != 'town') {
      return null;
    }
    const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(place);
    const dateState = date.overlapsDates(startDate, endDate);
    if(dateState[0] != PRESENT) {
      return null;
    }

    const isCapital = place.capitals.filter((id) => {
      const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(dataLookup.capitals[id]);
      const dateState = date.overlapsDates(startDate, endDate);
      return dateState[0] === PRESENT;
    }).length > 0;
    if(isCapital) {
      return null;
    }
    points[`places_${place.id}`] = [place.longitude, place.latitude];
    features.push({
      type: 'Feature',
      properties: {
        id: place.id,
        type: isCapital ? 'capital' : 'place',
        icon: isCapital ? 'capital_pin' : 'place_pin',
        selected: selectedEntity.entity === 'places' && Number(selectedEntity.id) === place.id ? 2 : 0,
      },
      geometry: { type: 'Point', coordinates: [place.longitude, place.latitude]}
    });
  });
  return {features, points};
}

function getScholarCollection(selectedEntity, date, lookup, dataLookup) {
  const points = {};
  const features = [];
  if(!lookup || !lookup.scholar_places) {
    return {features, points};
  }

  lookup.scholar_places.forEach((scholarPlaceId) => {
    const scholarPlace = dataLookup.scholar_places[scholarPlaceId];
    const scholar = dataLookup.scholars[scholarPlace.scholar_id];
    const place = dataLookup.places[scholarPlace.place_id];

    points[`scholars_${scholar.id}`] = [place.longitude, place.latitude];
    features.push({
      type: 'Feature',
      properties: {
        id: scholar.id,
        type: 'scholar',
        icon: 'scholar_pin',
        selected: selectedEntity.entity === 'scholars' && Number(selectedEntity.id) === scholar.id ? 2 : 0,
      },
      geometry: { type: 'Point', coordinates: [place.longitude, place.latitude]}
    });
  });
  return {features, points};
}

export const em = () => {};
