import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as config from '../config/index';

import AbstractEntityComponent from './AbstractEntityComponent';
import HeadingImage from './helpers/HeadingImage';
import Gallery from './helpers/Gallery';
import References from './helpers/References';
import Text from './helpers/Text';
import LinkList from './helpers/LinkList';

import {
  getCapitalByYear,
  getYearString,
  getRulerByYear,
  getEntityDateState,
} from '../helpers/date';

import * as DATE_STATES from '../helpers/date_states';

import { loadPage } from '../store/actions';
import { parseURL, getURL, getLink } from '../helpers/links';
import AlfehrestDate from '../helpers/AlFehrestDate';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
  listLookup: state.listLookup,
  pageCache: state.pageCache,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired
};
const defaultProps = {};

class State extends AbstractEntityComponent {
  constructor(props) {
    super(props);
  }

  getEntityType() {
    return 'state';
  }

  // TODO: Render Empty container when state is out of history
  renderEntity(data) {
    if (data.status === 'done') {
      let dateData = null;
      let startDate, endDate;
      [startDate, endDate] = AlfehrestDate.fromEntity(data);
      this.dateState = this.props.date.overlapsDates(startDate, endDate);
      const rulerList = this.props.listLookup.states.rulers[this.props.id];
      const monumentList= this.props.listLookup.states.monuments[this.props.id];
      const eventList= this.props.listLookup.states.events[this.props.id];

      switch (this.dateState[0]) {
        case DATE_STATES.PAST:
        case DATE_STATES.FUTURE:
          dateData = (
            <p>
              هذه الدولة لم تكن قائمة في العام
              {' '}
              <Link to={`/${this.props.date}`}>{this.props.date.toDefaultFormat()}</Link>
              {' '}
              {'('}<Link to={`/${startDate}/states/${data.id}`}>انتقل إلى سنة نشأتها</Link>{')'}
            </p>
          );
          break;
        case DATE_STATES.PRESENT:
          dateData = (
            <p>
              كان عمر هذه الدولة في
              {' '}
              <Link to={`/${this.props.date}`}>{this.props.date.toDefaultFormat()}</Link>
              {' '}
               حوالى {this.dateState[1]} سنة.
               {this.renderRuler(data)}
               {this.renderCapital(data)}
            </p>
          );
          break;
      }

      return (
        <div>
          <HeadingImage title={data.name} images={data.images} type={this.entityType} date={this.props.date} />
          {dateData}
          <Text text={data.description} maxLength={config.DESC_TEXT_LENGTH} />
          <Gallery images={data.images} />
          <LinkList title="حكام الدولة" entityType="rulers" list={rulerList} />
          <LinkList title="آثار الدولة" entityType="monuments" list={monumentList} />
          <LinkList title="أحداث متعلقة بالدولة" entityType="events" list={eventList} />
          <References references={data.references} />
        </div>
      );
    }
  }

  renderCapital() {
    const capital = getCapitalByYear(
      this.props.id,
      this.props.dataLookup,
      this.props.date
    );
    if(!capital) {
      return null;
    }

    const place = this.props.dataLookup.places[capital.place_id];
    return (
      <span>
        {' '}
        وكانت عاصمتها في ذلك الوقت
        {' '}
        { getLink('places', place, this.props.date) }
      </span>
    );
  }

  renderRuler() {
    const ruler = getRulerByYear(
      this.props.id,
      this.props.dataLookup,
      this.props.date
    );
    return !ruler ? null  : (
      <span>
        {' '}
        وكان حاكمها في ذلك الوقت
        {' '}
        {getLink('ruler', ruler, this.props.date)}
      </span>
    );
  }
}

State.defaultProps = defaultProps;
State.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(State);
