import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getURL } from '../helpers/links';
import HeadingImage from '../content_components/helpers/HeadingImage';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup
});

// Maps component props to action creators
const mapDispatchToProps = {
};

const propTypes = {
  year: PropTypes.number.isRequired
};
const defaultProps = {};

class States extends React.Component {


  render() {
    if (this.props.initialDataStatus !== 'done') {
      return null;
    }
    const listOfStates = Object.keys(this.props.dataLookup.states).map((key) => {
      const state = this.props.dataLookup.states[key];
      return (
        <li key={`state-${state.id}`}>
          <Link to={getURL('state', state.id, this.props.year)}>{state.name}</Link>
        </li>
      );
    });
    return (
      <div>
        <HeadingImage type="all-states" title="All Dynasties" images="" />
        <ol>{listOfStates}</ol>
      </div>
    );
  }
}

States.defaultProps = defaultProps;
States.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(States);
