export const START_YEAR = 700;
export const END_YEAR = 1500;
export const REF_TEXT_LENGTH = 40;
export const DESC_TEXT_LENGTH = 120;
export const SEARCH_MIN_LENGTH = 3;
export const SEARCH_MAX_LENGTH = 40;
export const SEARCH_LIST_MAX = 10;
export const EVENT_CLUSTER_RADIUS = 50;
export const MONUMENT_CLUSTER_RADIUS = 20;
export const ENABLED_ENTITIES = ['scholars', 'scholar_places', 'capitals', 'states', 'rulers', 'phases', 'monuments', 'events', 'places']
export const SETTINGS = {
  'show_scholars': ['عرض العلماء', true],
  'show_events': ['عرض الأحداث', true],
  'show_places': ['عرض المدن', false],
  'show_state_names': ['عرض أسماء الدول', true],
  'arabic_numerals': ['أرقام عربية', false]
};
