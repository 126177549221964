export const isDev = () => process.env.NODE_ENV === 'development';
export const isProd = () => process.env.NODE_ENV === 'production';
export const isTest = () => process.env.NODE_ENV === 'test';
export const isOnServer = () => !!process.env.SERVER_SIDE;

export const getEnv = (key, def) => {
  if(isProd()) {
    return global.env_vars[key];
  }
  const value = (process.env[key] || process.env[`REACT_APP_${key}`]) || def;
  return value;
};

