import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getURL } from '../helpers/links';

import * as config from '../config/index';

import AbstractEntityComponent from './AbstractEntityComponent';
import HeadingImage from './helpers/HeadingImage';
import Gallery from './helpers/Gallery';
import References from './helpers/References';
import Text from './helpers/Text';
import LinkList from './helpers/LinkList';
import AlfehrestDate from '../helpers/AlFehrestDate';


import { loadPage } from '../store/actions';
import {
  getYearString,
  getEntityDateState,
  yearInRange,
} from '../helpers/date';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
  listLookup: state.listLookup,
  pageCache: state.pageCache,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
};
const defaultProps = {};

class Place extends AbstractEntityComponent {
  constructor(props) {
    super(props);
  }

  getEntityType() {
    return 'place';
  }

  getDateset() {
    const { scholars, scholar_places } = this.props.dataLookup;
    const pageCache = {
      scholars: {},
      status: 'done',
    };
    Object.keys(scholar_places).forEach((key) => {
      if(scholar_places[key].place_id === this.props.id) {
        const scholarId = scholar_places[key].scholar_id;
        pageCache.scholars[scholarId] = scholars[scholarId];
      }
    });
    pageCache.scholars = Object.values(pageCache.scholars);
    return pageCache;
  }

  componentDidMount() {
  }


  renderScholars(data) {
    if (data.status === 'done') {
      const scholars = [];
      data.scholars.forEach((scholar) => {
        const key = `scholar_${scholar.id}`;
        const url = getURL(
          'scholar',
          scholar.id,
          new AlfehrestDate(scholar.a_year, scholar.a_month, scholar.a_day)
        );
        scholars.push(
          <li key={key}>
            <Link to={url}>{scholar.name}</Link>
          </li>
        );
      });

      switch(data.place_type) {
        case "town":
          return (
            <div>
              <h2>أعلام مرو بهذه المدينة</h2>
              <ol>{scholars}</ol>
            </div>
          );
      }
    }
  }

  renderEntity(data) {
    if (data.status === 'done') {
      const bgImage = data.images.length > 0 ? data.images[0] : "";
      return (
        <div>
          <HeadingImage title={data.name} images={data.images} type={this.entityType} date={this.props.date} />
          <Text text={data.description} maxLength={config.DESC_TEXT_LENGTH} />
          {this.renderScholars(this.getDateset())}
          <Gallery images={data.images} />
          <References references={data.links} />
        </div>
      );
    }
  }

}

Place.defaultProps = defaultProps;
Place.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Place);
