import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {fixNumerals} from '../../helpers/lang';

const mapStateToProps = (state) => ({
  settings: state.settings
});

const mapDispatchToProps = {};

const propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number
};
const defaultProps = {
  maxLength: 50,
};

class Text extends React.Component {
  constructor(props) {
    super(props);
    this.onReadMoreLess = this.onReadMoreLess.bind(this);
    this.state = {isExpanded: false};
  }

  onReadMoreLess() {
    this.setState({isExpanded: !this.state.isExpanded});
  }

  getText() {
    const txt = fixNumerals(this.props.text, this.props.settings.arabic_numerals ? 'arabic' : 'hindi') || "";
    const n = this.props.maxLength;
    if (this.state.isExpanded || txt.length <= n) {
      return txt === "null" ? "" : txt;
    }
    return `${txt.substr(0, n - 1)} ...`;
  }

  renderReadMoreLess() {
    const txt = this.props.text || "";
    if(txt.length <= this.props.maxLength) {
      return null;
    }
    return (
      <span>
        {' '}
        <span className="read-more" onClick={this.onReadMoreLess}>
          {this.state.isExpanded ? 'الأقل' : 'قراءة المزيد'}
        </span>
      </span>
    );
  }

  render() {
    return (
      <p className="desc">
        {this.getText()}
        {this.renderReadMoreLess()}
      </p>
    );
  }
}

Text.defaultProps = defaultProps;
Text.propTypes = propTypes;


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Text);
