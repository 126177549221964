import React from 'react';
import PropTypes from 'prop-types';

import {  getURL } from '../helpers/links';
import AlfehrestDate from '../helpers/AlFehrestDate';

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
};
const defaultProps = {};

class AbstractEntityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onRetryClicked = this.onRetryClicked.bind(this);
    this.entityId = null;
    this.entityType = this.getEntityType();
    this.url = null;
    this.dateState = null;
    this.reload();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.id !== this.props.id) {
      this.reload();
    }
  }

  onRetryClicked() {
    this.props.loadPage(this.url, this.props.pageCache);
  }

  reload() {
    if(this.entityType === null) {
      throw new Error('Please set this.entityType');
    }
    this.url = getURL(this.entityType, this.props.id);
    this.props.loadPage(this.url, this.props.pageCache);
  }

  renderEntity(data) {
    throw new Error('No implemented');
  }

  renderLoading(data) {
    if (data.status === 'failed') {
      return (
        <div className="failed">
          <p>
            Failed to reach the server. Please verify that you are connected to
            the Internet.
          </p>
          <button onClick={this.onRetryClicked}>Retry</button>
        </div>
      );
    }
    if (data.status === 'fetching') {
      return null;
    }
  }

  render() {
    if (this.props.initialDataStatus !== 'done') {
      return <span />;
    }
    const data = this.props.pageCache[this.url];
    return (
      <div className={data.status}>
        {this.renderLoading(data)}
        {this.renderEntity(data)}
      </div>
    );
  }
}

AbstractEntityComponent.defaultProps = defaultProps;
AbstractEntityComponent.propTypes = propTypes;

export default AbstractEntityComponent;
