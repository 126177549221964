import React from 'react';
import PropTypes from 'prop-types';

import * as config from '../../config/index';


const propTypes = {
  references: PropTypes.string.isRequired
};
const defaultProps = {};

class References extends React.Component {

  renderItems(items) {
    const output = [];
    let counter = 0;
    items.forEach((item) => {
      let text = item;
      let url = null;
      let html = <span>{text}</span>;
      if(item.startsWith('http')) {
        url = item;
        text = item.length > config.REF_TEXT_LENGTH ?  `${item.substr(0, config.REF_TEXT_LENGTH)}...` : item;
        html = <a target="_blank" rel="noopener noreferrer" href={url}>{text}</a>;
      }
      counter += 1;
      output.push(
        <li key={`ref-${counter}`}>
          {html}
        </li>
      );
    });
    return output;
  }

  render() {
    let ref = [];

    try {
      ref = JSON.parse(this.props.references);
    } catch(e) { ; }
    if(ref.length === 0) {
      return null;
    }
    return (
      <div className="references">
        <h4>المراجع</h4>
        <ol>
          {this.renderItems(ref)}
        </ol>
      </div>
    );
  }
}

References.defaultProps = defaultProps;
References.propTypes = propTypes;

export default References;
