import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import polylabel from '@mapbox/polylabel';
import {bbox, flatten, area} from '@turf/turf';
import Color from 'color';
import { getYearString, getRulerByYear } from '../helpers/date';
import history from '../history';
import { loadPage } from '../store/actions';
import { getURL, getLink } from '../helpers/links';
import * as LOADING_STATES from '../helpers/loading_states';
import AlfehrestDate from '../helpers/AlFehrestDate';
import {addImages} from '../helpers/mapbox';
import { PRESENT } from "../helpers/date_states";


import capitalIcon from '../assets/images/pins/capital_pin.png';
import capitalIconSelected from '../assets/images/pins/capital_selected_pin.png';


const mapStateToProps = (state) => ({
  dataLookup: state.dataLookup,
  pageCache: state.pageCache,
  dateLookup: state.dateLookup,
  settings: state.settings
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
  map: PropTypes.any.isRequired,
  offset: PropTypes.number.isRequired
};
const defaultProps = {};

class StateLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.shouldCenter = true;
    this.initState = LOADING_STATES.NOT_STARTED;
    this.onLayerClicked = this.onLayerClicked.bind(this);
    this.bbox = {};
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.initState !== LOADING_STATES.COMPLETE) {
      return;
    }

    if(this.props.selectedEntity !== prevProps.selectedEntity) {
      this.shouldCenter = this.props.selectedEntity !== null;
    }

    this.updateMap();

    this.centerMap();
  }

  onLayerClicked(ev, feature) {
    history.push(getURL('state', feature.properties.state_id, this.props.date));
  }

  getPhasesToUpdate(prevProps, prevState) {
    return true;
  }

  getLabelCollection(src) {
    const data = {
      type: 'FeatureCollection',
      features: [],
    };

    src.features.forEach((feature) => {

      const features = flatten(feature).features;
      features.sort((a, b) => area(a) - area(b));
      const largestFeature = features[0];
      const id = largestFeature.properties.state_id;
      const state =  this.props.dataLookup.states[id];
      data.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: polylabel(largestFeature.geometry.coordinates),
        },
        properties: {
          title: state.name,
          state_id: state.id,
          selected: state.id === parseInt(this.props.selectedEntity, 10) ? 2 : 0,
          color: Color(state.color).darken(0.45).string()
        },
      });
    });
    return data;
  }

  getCollections() {
    this.bbox = {};
    const lookup = this.props.dateLookup[this.props.date.toHash()];
    const phaseCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const capitalCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (!lookup || !lookup.phases) {
      return [phaseCollection, capitalCollection];
    }

    lookup.phases.forEach((phaseId) => {
      const phase = this.props.dataLookup.phases[phaseId];
      const state = this.props.dataLookup.states[phase.state_id];

      const capital = state.capitals.filter((id) => {
        const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(this.props.dataLookup.capitals[id]);
        const dateState = this.props.date.overlapsDates(startDate, endDate);
        return dateState[0] === PRESENT;
      }).flatMap((id) => this.props.dataLookup.capitals[id])[0];

      if(capital) {
        const place = this.props.dataLookup.places[capital.place_id];
        capitalCollection.features.push({
          type: 'Feature',
          properties: {
            id: place.id,
            type: 'capital',
            state_id: capital.state_id,
            selected: Number(this.props.selectedEntity) === capital.state_id ? 2 : 0,
          },
          geometry: { type: 'Point', coordinates: [place.longitude, place.latitude]}
        });
      }

      const p = phase.geojson.features[0];
      this.bbox[phase.state_id] = bbox(p);
      p.properties.state_id = phase.state_id;
      p.properties.color = state.color;
      p.properties.selected = 0;
      if(p.properties.state_id === parseInt(this.props.selectedEntity, 10)) {
        p.properties.selected = 2;
      }
      phaseCollection.features.push(p);
    });
    return [phaseCollection, capitalCollection];
  }

  queryLayers() {
    return ['phase-layer', 'phase-label-layer', 'phase-capital-layer'];
  }

  initMap() {
    if (this.props.map && this.initState === LOADING_STATES.NOT_STARTED) {
      this.initState = LOADING_STATES.STARTED;
      this.props.map.addSource('phase-src', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      this.props.map.addSource('phase-label-src', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      this.props.map.addSource('phase-capital-src', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });

      this.props.map.addLayer({
        id: 'phase-layer',
        type: 'fill',
        source: 'phase-src',
        paint: {
          'fill-color': ["get", "color"],
          'fill-opacity': {
            property: 'selected',
            stops: [[0,  0.75], [2, 0.99]]
          },
        },
      });

      this.props.map.addLayer({
        id: 'phase-label-layer',
        type: 'symbol',
        source: 'phase-label-src',
        paint: {
          'text-color': ["get", "color"]
        },
        layout: {
          'text-field': '{title}',
          'text-font': ['Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-size': 15,
          'text-anchor': 'center'
        },
      });

      addImages(this.props.map, [
        {url: capitalIcon, id: 'capital_pin'},
        {url: capitalIconSelected, id: 'capital_selected_pin'}
      ]).then(() => {
        this.props.map.addLayer({
          id: 'phase-capital-layer',
          type: 'symbol',
          source: 'phase-capital-src',
          layout: {
            'icon-image': {
              property: 'selected',
              stops: [[0,  'capital_pin'], [2, 'capital_selected_pin']]
            },
            'icon-size': 0.06,
            'icon-allow-overlap': true,
          },
        });
      });

      this.props.map.addLayer({
        id: 'phase-layer-outline',
        type: 'line',
        source: 'phase-src',
        paint: {
          'line-width': ['get', 'selected'],
          'line-color': '#FFDF00'
        },
      });
      this.initState = LOADING_STATES.COMPLETE;

      this.updateMap();
      this.centerMap();
    }
  }

  updateMap() {
    const [phases, capitals] = this.getCollections();
    this.props.map.getSource('phase-src').setData(phases);
    this.props.map.getSource('phase-capital-src').setData(capitals);
    if(this.props.settings.show_state_names) {
      this.props.map
        .getSource('phase-label-src')
        .setData(this.getLabelCollection(phases));
    } else {
      this.props.map
        .getSource('phase-label-src')
        .setData({ type: 'FeatureCollection', features: [] });
    }
  }

  centerMap() {
    if(this.props.map) {
      const b = this.bbox[this.props.selectedEntity];
      if(b) {
        this.props.map.fitBounds(b, {
          offset: [this.props.offset, 0],
          padding: 300
        });
      }
      this.shouldCenter = false;
    }
  }

  render() {
    return null;
  }
}

StateLayer.defaultProps = defaultProps;
StateLayer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  { withRef: true }
)(StateLayer);
