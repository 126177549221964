const CONST_NUMBERS = {
	'0' : '٠',
	'1' : '١',
	'2' : '٢',
	'3' : '٣',
	'4' : '٤',
	'5' : '٥',
	'6' : '٦',
	'7' : '٧',
	'8' : '٨',
	'9' : '٩'
};
export const fixNumerals = (text, mode) => {
  let input = "" + text;
  if(mode === 'arabic') {
    return input;
  }
	for(let idx in CONST_NUMBERS){
		input =  input.replace(new RegExp(idx, 'gi'), CONST_NUMBERS[idx])
	}
	return input;
}