
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {shouldInlineMap} from '../../helpers/layout';
import Map from '../../Map';
import {parseImages} from '../../helpers/data';
import AlfehrestDate from '../../helpers/AlFehrestDate';


const propTypes = {
  title: PropTypes.string.isRequired,
  type:  PropTypes.oneOf(['event', 'monument', 'state', 'city', 'year', 'ruler', 'scholar']),
  images: PropTypes.string,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
};
const defaultProps = {};

const mapStateToProps = (state, ownProps) => ({
  isSidePanelOpen: state.isSidePanelOpen
});
const mapDispatchToProps = {};

class HeadingImage extends React.Component {


  render() {
    const mapHeadingTypes = ['state', 'city', 'monument', 'event', 'scholar'];
    const showMap = shouldInlineMap() && this.props.isSidePanelOpen && mapHeadingTypes.indexOf(this.props.type) !== -1;

    const images = parseImages(this.props.images);
    const bgImage = images.length ? images[0].url : null;

    const classList = ['heading-image'];
    if(!bgImage) {
      classList.push(...['generic', this.props.type]);
    }
    if(showMap) {
      classList.push('with-map');
    }
    const headingSizeLookup = {
      0: 'normal',
      30: 'medium',
      50: 'small',
    };

    const keys = Object.keys(headingSizeLookup);
    let cls = headingSizeLookup[keys[keys.length-1]];
    for(let i=0; i<keys.length - 1; i++) {
      const k0 = keys[i];
      const k1 = keys[i+1];
      if(this.props.title.length > k0 && this.props.title.length <= k1) {
        cls = headingSizeLookup[k0];
        break;
      }
    }


    return (
      <div style={{backgroundImage: `url(${bgImage})`}} className={classList.join(' ')}>
        <h1 className={cls}>{this.props.title}</h1>
        {showMap && <Map date={this.props.date} locked />}
      </div>
    );
  }
}

HeadingImage.defaultProps = defaultProps;
HeadingImage.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeadingImage);
