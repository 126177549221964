import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as socialHelper from './helpers/social'
import {loadInitialData} from './store/actions'

const mapStateToProps = state => ({
  initialDataStatus: state.initialDataStatus
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadInitialData: loadInitialData
}

const propTypes = {
  initialDataStatus: PropTypes.oneOf([
    'not_started', 'fetching', 'failed', 'done'
  ]),
};
const defaultProps = {};


class Overlay extends React.Component {


  constructor(props) {
    super(props);
    this.state = {};
    this.onRetryClicked = this.onRetryClicked.bind(this);
    this.props.loadInitialData();
  }

  onRetryClicked() {
    this.props.loadInitialData();
  }

  renderFailure() {
    if(this.props.initialDataStatus === 'failed') {
      return (
        <div className='failed'>
          <p>
          Failed to reach the server.
          Please verify that you are connected to the Internet.
          </p>
          <button onClick={this.onRetryClicked}>Retry</button>
        </div>
      )
    }
  }

  renderLoading() {
    if(this.props.initialDataStatus === 'fetching') {
      return (<div className='loading'></div>)
    }
  }


  render() {
    const loadingState = this.props.initialDataStatus;
    return (
      <div id='global-overlay' hidden={loadingState === 'done'}>
        {this.renderLoading()}
        {this.renderFailure()}
      </div>
    );
  }


}

Overlay.defaultProps = defaultProps
Overlay.propTypes = propTypes

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overlay);