import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import './App.css';
import App from './App';
import history from './history';
import configureStore from './store/store';
import defaultState from './store/defaultState';


const propTypes = {};
const defaultProps = {};
export let store = null;
export class Root extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      ...defaultState,
    };
    store = this.store = configureStore(this.initialState);
  }

  render() {
    return (
      <Router history={history}>
        <Provider store={this.store}>
        <Switch>
          <Route path="/:date/" component={App} />
          <Redirect from="/" to={`/${this.initialState.currentDate.toJD()}`} />
        </Switch>
        </Provider>
      </Router>
    );
  }
}

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;