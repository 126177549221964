import { applyMiddleware, createStore } from 'redux';
import { logger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducer';
import {isDev} from '../helpers/env';

export default function configureStore(initialState) {
  const middlewares = [thunkMiddleware];
  if (isDev()) {
    //middlewares.push(logger);
  }
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middlewares)
  );

  if (isDev() && module.hot) {
    module.hot.accept('./reducer', () => {
      /* eslint-disable global-require */
      store.replaceReducer(require('./reducer').default);
      /* eslint-enable global-require */
    });
  }

  return store;
}
