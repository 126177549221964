import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadPage } from '../../store/actions';
import { getURL } from '../../helpers/links';
import AlfehrestDate from '../../helpers/AlFehrestDate';

const mapStateToProps = state => ({
  pageCache: state.pageCache,
  dataLookup: state.dataLookup,
});

const mapDispatchToProps = {
  loadPage: loadPage
};

const propTypes = {
  entityType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  list:  PropTypes.arrayOf(PropTypes.object).isRequired
};
const defaultProps = {};

class LinkList extends React.Component {

  renderLinks(entities) {
    const list = [];
    entities.forEach((entity) => {
      const {id, name} = entity;
      const [startDate, _] = AlfehrestDate.fromEntityAtoZ(entity);

      const key = `${id}-${name}`;
      list.push(
        <li key={key}>
          <Link to={getURL(this.props.entityType, id, startDate)}>{name}</Link>
        </li>
      );
    });
    return list;
  }


  render() {
    if(this.props.list.length === 0) {
      return null;
    }
    const className = ["link-list", this.props.entityType].join(" ")
    return (
      <div className={className}>
        <h4>{this.props.title}</h4>
        <ol>
          {this.renderLinks(this.props.list)}
        </ol>
      </div>
    );
  }
}

LinkList.defaultProps = defaultProps;
LinkList.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkList);
