function swap(json){
  const ret = {};
  for(let key in json){
    ret[json[key]] = key;
  }
  return ret;
}
const pluralToSingleLookup = {
  //cities: 'city',
  monuments: 'monument',
  states: 'state',
  rulers: 'ruler',
  events: 'event',
  scholars: 'scholar',
  places: 'place'
};
const singleToPluralLookup = swap(pluralToSingleLookup);

export function getSingular(entity) {
  if(entity in pluralToSingleLookup) {
    return `${pluralToSingleLookup[entity]}`;
  }
  return null;
}

export function getFkFromPlural(entity) {
  if(entity in pluralToSingleLookup) {
    return `${pluralToSingleLookup[entity]}_id`;
  }
  return null;
}

export function empty() {}
