import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import { getYearString } from '../helpers/date';
import { getURL } from '../helpers/links';

import { gotoDate, loadPage } from '../store/actions';
import HeadingImage from './helpers/HeadingImage';
import {ENABLED_ENTITIES} from '../config/index';
import AlfehrestDate from '../helpers/AlFehrestDate';
import { PRESENT } from '../helpers/date_states';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
});

const mapDispatchToProps = {
  gotoDate: gotoDate,
};

const propTypes = {
  year: PropTypes.number.isRequired,
};
const defaultProps = {};

class Year extends React.Component {
  constructor(props) {
    super(props);
    this.year = null;
    this.url = null;
  }

  getDateset(date) {
    const { scholars, rulers, events, states } = this.props.dataLookup;
    const pageCache = {
      states: [],
      scholars: [],
      events: [],
      status: 'done',
    };
    Object.values(states).forEach((s) => {
      const state = JSON.parse(JSON.stringify(s));
      const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(state);
      const dateState = date.overlapsDates(startDate, endDate);
      if(dateState[0] != PRESENT) {
        return null;
      }
      const rulerId = state.rulers.filter((rulerId, idx) => {
        const [rStartDate, rEndDate] = AlfehrestDate.fromEntity(rulers[rulerId]);
        const rDateState = date.overlapsDates(rStartDate, rEndDate);
        return rDateState[0] == PRESENT;
      }).pop();
      state.currentRuler = rulerId ? rulers[rulerId] : undefined;
      pageCache.states.push(state);
    });

    ['scholars', 'events'].forEach((entityType) => {
      Object.values(this.props.dataLookup[entityType]).forEach((entity) => {
        const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(entity);
        const dateState = date.overlapsDates(startDate, endDate);
        if(dateState[0] != PRESENT) {
          return null;
        }
        pageCache[entityType].push(entity);
      });
    });

    return pageCache;
  }

  componentDidMount() {
    this.props.gotoDate(this.props.date, this.props.initialData);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps && !prevProps.date.equals(this.props.date)) {
      this.props.gotoDate(this.props.date, this.props.initialData);
    }
  }

  renderEvents(data) {
    return null;
  }

  renderScholars(data) {
    if (data.status === 'done') {
      const scholars = [];
      data.scholars.forEach((scholar) => {
        const key = `scholar_${scholar.id}`;
        const url = getURL('scholar', scholar.id, this.props.date);
        scholars.push(
          <li key={key}>
            <Link to={url}>{scholar.name}</Link>
          </li>
        );
      });

      return (
        <div>
          <h2>علماء تلك الفترة</h2>
          <ol>{scholars}</ol>
        </div>
      );
    }
  }

  renderStates(data) {
    if (data.status === 'done') {
      const states = [];
      data.states.forEach((state) => {
        const key = `state_${state.id}`;
        const url = getURL('state', state.id, this.props.date);

        const rulerName = state.currentRuler
          ? state.currentRuler.name
          : 'Unknown';
        const rulerUrl = state.currentRuler
          ? getURL('ruler', state.currentRuler.id, this.props.date)
          : null;
        const rulerLink = rulerUrl
          ? <Link to={rulerUrl}>{rulerName}</Link>
          : <span>{rulerName}</span>;
        states.push(
          <li key={key}>
            <Link to={url}>{state.name}</Link> وحاكمها في ذلك الوقت كان{' '} {rulerLink}
          </li>
        );
      });

      return (
        <div>
          <h2>دول تلك الفترة</h2>
          <ol>{states}</ol>
        </div>
      );
    }
  }

  render() {
    const data = this.getDateset(this.props.date);
    if (this.props.initialDataStatus === 'done' && data) {
      return (
        <div>
          <HeadingImage type="year" title={`${this.props.date.toStringFormat("%B %Y%c")} - ${this.props.date.toHijri().toStringFormat("%B %Y%c")}`} images="" />
          {this.renderStates(data)}
          {this.renderScholars(data)}
          {this.renderEvents(data)}
        </div>
      );
    }
    return null;
  }
}
Year.defaultProps = defaultProps;
Year.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Year);
