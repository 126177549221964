import { socialLinks } from '../config/social';

function getPageShareInfo() {
  const loc = typeof window !== 'undefined' && window && window.location;
  const url = `${loc.protocol}//${loc.hostname}${loc.pathname}`;
  return {
    url: url,
    description: '',
  };
}

export function getFacebookLink() {
  const pageInfo = getPageShareInfo();
  return socialLinks.facebook
    .replace('%u%', pageInfo.url)
    .replace('%t%', pageInfo.description);
}

export function getGoogleLink() {
  const pageInfo = getPageShareInfo();
  return socialLinks.google
    .replace('%u%', pageInfo.url)
    .replace('%t%', pageInfo.description);
}

export function getTwitterLink() {
  const pageInfo = getPageShareInfo();
  return socialLinks.twitter
    .replace('%u%', pageInfo.url)
    .replace('%t%', pageInfo.description);
}

export function getRedditLink() {
  const pageInfo = getPageShareInfo();
  return socialLinks.reddit
    .replace('%u%', pageInfo.url)
    .replace('%t%', pageInfo.description);
}
