
export function addImage(map, id, url) {
  return new Promise((resolve, reject) => {
    map.loadImage(url, (error, image) => {
      if(error) {
        reject(error);
        return;
      }
      map.addImage(id, image);
      resolve(image);
    });
  });
}

export function addImages(map, images) {
  const promises = images.map( imageData => addImage(map, imageData.id, imageData.url) );
  return Promise.all(promises);
}