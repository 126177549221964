import React from 'react';

import HeadingImage from '../content_components/helpers/HeadingImage';

class Help extends React.Component {
  render() {
    return (
      <div>
        <HeadingImage images="" title="Help" type="help" />
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor urna, posuere at iaculis a, consequat vel ex. Sed molestie dictum magna, in convallis metus lobortis vel. In placerat, augue id congue posuere, mauris neque efficitur purus, in pulvinar neque risus in enim. Aliquam sagittis tortor nec ligula tempor, ac vehicula augue tincidunt. Curabitur vitae tristique urna. Maecenas a feugiat diam. Nam sed nibh eu ex aliquet lacinia sit amet sed sem. Fusce volutpat varius ipsum in molestie. Ut eget tortor ac metus efficitur pulvinar. Suspendisse lacinia mollis libero, vel pellentesque ex pulvinar suscipit. Ut iaculis ultrices velit, a faucibus libero pharetra non.
        </p>
      </div>
    );
  }
}

export default Help;
