import { getEnv } from '../helpers/env';
import {ENABLED_ENTITIES} from '../config/index';

export const GOTO_DATE = 'GOTO_DATE';
export const GOTO_LIST = 'GOTO_LIST';
export const GOTO_PHASE = 'GOTO_PHASE';
export const UPDATE_SETTING = 'UPDATE_SETTINGS';
export const TOGGLE_SIDEPANEL = 'TOGGLE_SIDEPANEL';
export const TOGGLE_LIGHTBOX = 'TOGGLE_LIGHTBOX';

export const gotoList = (list) => ({ type: GOTO_LIST, data: list });
export const gotoDate = (date, shouldChangePage) => {
  return {
    type: GOTO_DATE,
    data: { date, shouldChangePage },
  };
};
export const toggleSidePanel = (state) => ({
  type: TOGGLE_SIDEPANEL,
  data: state,
});
export const toggleLightBox = (state) => ({
  type: TOGGLE_LIGHTBOX,
  data: state,
});
export const updateSetting = (key, value) => ({
type: UPDATE_SETTING,
  data: {key, value}
})

export const LOAD_DATA_REQUEST = 'LOAD_DATA_REQUEST';
export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS';
export const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE';

export const LOAD_PAGE_REQUEST = 'LOAD_PAGE_REQUEST';
export const LOAD_PAGE_SUCCESS = 'LOAD_PAGE_SUCCESS';
export const LOAD_PAGE_FAILURE = 'LOAD_PAGE_FAILURE';


function fetchURL(url) {
  const fetchInfo = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    headers: {
      'Accept': 'application/vnd.alfehrest.org+json;version=3',
      //'Accept-Langauge': 'ar'
    }
  }
  return fetch(url, fetchInfo);
}

export const loadInitialData = (initialData = null) => {
  if (initialData) {
    return (dispatch) => {
      dispatch(loadInitialDataSuccess(initialData));
      return new Promise(resolve => resolve(initialData));
    };
  }

  const map = ENABLED_ENTITIES;

  // Asynchronus action
  return (dispatch) => {
    const promises = [];
    map.forEach((entity) => {
      promises.push(
        fetchURL(`${getEnv('API_URL')}/${entity}.json`).then((response) =>
          response.json()
        )
      );
    });
    dispatch({ type: LOAD_DATA_REQUEST });
    return Promise.all(promises)
      .then((json) => {
        const initialData = {};
        map.forEach((entity, idx) => {
          initialData[entity] = json[idx];
        });
        return dispatch(loadInitialDataSuccess(initialData));
      })
      .catch(() => dispatch(loadInitialDataFailure()));
  };
};

const loadInitialDataSuccess = (data) => ({
  type: LOAD_DATA_SUCCESS,
  data: data,
});
const loadInitialDataFailure = () => ({ type: LOAD_DATA_FAILURE });

export const loadPage = (url, cache, initialData) => {
  if (url in cache && cache[url].status === 'done') {
    switch (cache[url].status) {
      case 'done':
        return (dispatch) => {
          // Simulate page loading
          setTimeout(() => {
            dispatch({
              type: LOAD_PAGE_SUCCESS,
              data: { url: url, data: cache[url] },
            });
          }, 0);
          dispatch({
            type: LOAD_PAGE_REQUEST,
            data: { url },
          });
        };

      case 'fetching':
        return null;
    }
  }

  // Asynchronus action
  return (dispatch) => {
    // First we dispatch an action signifying we sent a request
    fetchURL(`${getEnv('API_URL')}${url}.json`)
      .then(response => response.json())
      .then(json => dispatch(loadPageSuccess(url, json)))
      .catch(() => dispatch(loadPageFailure(url)));
    return dispatch({
      type: LOAD_PAGE_REQUEST,
      data: { url },
    });
  };
};

const loadPageSuccess = (url, data) => ({
  type: LOAD_PAGE_SUCCESS,
  data: { url, data },
});

const loadPageFailure = url => ({ type: LOAD_PAGE_FAILURE, data: { url } });
