import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fixNumerals} from '../helpers/lang';

import history from '../history';
import { getURL, getLink } from '../helpers/links';
import * as cfg from '../config/index';
import * as LOADING_STATES from '../helpers/loading_states';
import { loadPage, gotoList, toggleSidePanel } from '../store/actions';
import AlfehrestDate from '../helpers/AlFehrestDate';
import {getIconGeoJSON} from '../helpers/data';

import {addImages} from '../helpers/mapbox';

import scholarIcon from '../assets/images/pins/scholar_pin.png';
import monumentIcon from '../assets/images/pins/monument_pin.png';
import placeIcon from '../assets/images/pins/place_pin.png';
import mixIcon from '../assets/images/pins/mix_pin.png'
import eventBattleIcon from '../assets/images/pins/event_battle_pin.png'
import eventGenericIcon from '../assets/images/pins/event_generic_pin.png';
import eventUprisingIcon from '../assets/images/pins/event_uprising_pin.png';


import eventScholarIcon from '../assets/images/pins/event_scholar_pin.png';
import eventMonumentIcon from '../assets/images/pins/event_monument_pin.png';
import monumentScholarIcon from '../assets/images/pins/monument_scholar_pin.png';
import placeScholarIcon from '../assets/images/pins/place_scholar_pin.png';


const mapStateToProps = (state, ownProps) => ({
  pageCache: state.pageCache,
  dataLookup: state.dataLookup,
  dateLookup: state.dateLookup,
  currentDate: ownProps.date,
  settings: state.settings
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
  gotoList: gotoList,
  toggleSidePanel: toggleSidePanel,
};

const propTypes = {
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
  map: PropTypes.any.isRequired,
  offset: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  speed: PropTypes.number.isRequired,
  selectedEntity: PropTypes.object.isRequired
};
const defaultProps = {};

class IconLayer extends React.Component {
  constructor(props) {
    super(props);
    this.entities = ['scholar', 'event', 'monument', 'place'];
    this.state = {};
    this.shouldCenter = true;
    this.points = {};
    this.features = [];
    this.initState = LOADING_STATES.NOT_STARTED;
    this.onLayerClicked = this.onLayerClicked.bind(this);
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.initState !== LOADING_STATES.COMPLETE) {
      return;
    }

    if (this.props.selectedEntity.entity !== prevProps.selectedEntity.entity ||
      this.props.selectedEntity.id !== prevProps.selectedEntity.id) {
      this.shouldCenter = true;
    }

    this.updateMap();

    if (this.shouldCenter) {
      this.centerMap();
    }
  }

  onLayerClicked(ev, feature) {
    if (feature.properties.cluster) {
      const pointsInCluster = this.features.filter((f) => {
        const pointPixels = this.props.map.project(f.geometry.coordinates);
        const pixelDistance = Math.sqrt(
          ((ev.point.x - pointPixels.x) ** 2) + ((ev.point.y - pointPixels.y) ** 2)
        );
        return Math.abs(pixelDistance) <= cfg.MONUMENT_CLUSTER_RADIUS;
      });
      history.push(
        getURL('list', pointsInCluster.map(p => [p.properties.type, p.properties.id]), this.props.date)
      );
      this.props.toggleSidePanel(true);
    } else {
      history.push(
        getURL(feature.properties.type, feature.properties.id, this.props.date)
      );
      this.props.toggleSidePanel(true);
    }
  }

  centerMap() {
    if (this.props.map) {
      let b = this.points[this.props.selectedEntity.entity + "_" + this.props.selectedEntity.id];
      if(!b) {
        let obj;
        try {
          obj = this.props.dataLookup[this.props.selectedEntity.entity][this.props.selectedEntity.id]
        } catch(e) {;}
        b = obj && obj.latitude ? [obj.latitude, obj.longitude] : null;
      }
      if(b) {
        this.props.map.flyTo({
          center: b,
          speed: this.props.speed,
          offset: [this.props.offset, 0],
          zoom: this.props.zoom
        });
      }
      this.shouldCenter = false;
    }
  }

  render() {return null;}

  getCollection() {
    const lookup = this.props.dateLookup[this.props.currentDate.toHash()];
    const collection = {
      type: 'FeatureCollection',
      features: [],
    };

    this.features = [];
    this.points = {};

    if (!lookup) {
      return collection;
    }

    this.entities.filter((e) => this.props.settings[`show_${e}s`]).forEach((entity) => {
      const {features, points} = getIconGeoJSON(entity, this.props.selectedEntity, this.props.currentDate, lookup, this.props.dataLookup);
      features.forEach((feature) => {
        feature.properties['arabic_numerals'] = this.props.settings['arabic_numerals'];
      });
      collection.features.push(...features);
      this.points = {...this.points, ...points};
      this.features.push(...features);
    });
    return collection;
  }

  initMap() {
    if (this.props.map && this.initState === LOADING_STATES.NOT_STARTED) {
      this.initState = LOADING_STATES.STARTED;
      const es = ['scholar', 'event', 'monument', 'place', 'capital'];
      es.sort();
      const clusterProps = {
        'icon': ["concat", ["get", "icon"], ""],
        'has_selected':    ["any", ["==", ["get", "selected"],       false],    2],
        'arabic_numerals': ["all", ["==", ["get", "arabic_numerals"], true], true]
      };
      es.forEach((e) => {
        clusterProps[`has_${e}`]  = ["any", ["==", ["get", "type"], e], false];
        clusterProps[`only_${e}`] = ["all", ["==", ["get", "type"], e], true];
      });

      const output = [];
      const lookup = {};
      function getCombinations(input, es) {
        input.sort();
        if(es.length == 0) {
          return input;
        }

        for(let entity of es) {
          if(input.length > 1) {
            const hash = input.join("_");
            if(!(hash in lookup)) {
              output.push([...input]);
             lookup[hash] = true;
            }
          }
          getCombinations([...input, entity], es.filter(e => e !== entity));
          getCombinations([...input], es.filter(e => e !== entity))
        }
        return output;
      }

      const numeralCases = [
        'case',
      ];
      const limit = 20
      for(let i=1; i<limit; i++) {
        numeralCases.push([
          'all',
          ['==', i, ['get', 'point_count']],
          ['==', true, ['get', 'arabic_numerals']]
        ]);
        numeralCases.push(fixNumerals(i, 'arabic'));
        numeralCases.push([
          'all',
          ['==', i, ['get', 'point_count']],
          ['==', false, ['get', 'arabic_numerals']]
        ]);
        numeralCases.push(fixNumerals(i, 'hindi'));
      }
      numeralCases.push(`${limit}`);

      const combinations = [...getCombinations([], es), es];
      const combCases = combinations.flatMap((oneCase) => {
        return [
          ["all", ...oneCase.map((e) => ["get", `has_${e}`])],
          oneCase.join("_") + "_pin"
        ]
      });
      const pureCases = es.flatMap((entity) => {
        return [["get", `only_${entity}`], `${entity}_pin`]
      });

      this.props.map.addSource('icon-src', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: cfg.MONUMENT_CLUSTER_RADIUS, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: clusterProps
      });

      addImages(this.props.map, [
        {url: scholarIcon, id: 'scholar_pin'},
        {url: monumentIcon, id: 'monument_pin'},
        {url: placeIcon, id: 'place_pin'},
        {url: mixIcon, id: 'mix_pin'},
        {url: eventBattleIcon, id: 'event_battle_pin'},
        {url: eventGenericIcon, id: 'event_generic_pin'},
        {url: eventUprisingIcon, id: 'event_uprising_pin'},
        {url: eventScholarIcon, id: 'event_scholar_pin'},
        {url: monumentScholarIcon, id: 'monument_scholar_pin'},
        {url: placeScholarIcon, id: 'place_scholar_pin'},
        {url: eventMonumentIcon, id: 'event_monument_pin'},
      ]).then(() => {
        this.props.map.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'icon-src',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': [
              "case",
              ...combCases,
              ...pureCases,
              ["get", "icon"]
            ],
            'icon-size': 0.06,
            'icon-allow-overlap': true,
          },
        });

        this.props.map.addLayer({
          id: 'cluster-count-bg',
          type: 'circle',
          source: 'icon-src',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'case',
              ['get', 'has_selected'],
              'red',
              '#51bbd6'
            ],
            'circle-radius': 8,
            'circle-translate': [-15, -15],
          },
        });

        this.props.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'icon-src',
          filter: ['has', 'point_count'],
          paint: {
            'text-translate': [-15, -15],
          },
          layout: {
            'text-field': numeralCases,
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-allow-overlap': true,
          },
        });

        this.props.map.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'icon-src',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': ["get", "icon"],
            'icon-size': {
              property: 'selected',
              stops: [[0,  0.06], [2, 0.1]]
            },
            'icon-allow-overlap': true,
          },
        });
        this.initState = LOADING_STATES.COMPLETE;
        this.updateMap();
        this.centerMap();
      }).catch(() => {
        this.initState = LOADING_STATES.FAILED;
      });
    }
  }

  updateMap() {
    this.props.map.getSource('icon-src').setData(this.getCollection());
  }

  queryLayers() {
    return [
      'clusters',
      'cluster-count',
      'cluster-count-bg',
      'unclustered-point',
    ];
  }
}

IconLayer.defaultProps = defaultProps;
IconLayer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  undefined,
  { withRef: true }
)(IconLayer);
