import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AbstractEntityComponent from './AbstractEntityComponent';
import Gallery from './helpers/Gallery';
import HeadingImage from './helpers/HeadingImage';
import References from './helpers/References';
import Text from './helpers/Text';
import LinkList from './helpers/LinkList';

import { getYearString, getEntityDateState,} from '../helpers/date';
import * as DATE_STATES from '../helpers/date_states';
import { loadPage } from '../store/actions';
import AlfehrestDate from '../helpers/AlFehrestDate';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
  listLookup: state.listLookup,
  pageCache: state.pageCache,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
};
const defaultProps = {};

class Event extends AbstractEntityComponent {
  constructor(props) {
    super(props);
  }

  getEntityType() {
    return 'event';
  }

  // TODO: Render Empty container when state is out of history
  renderEntity(data) {
    if (data.status === 'done') {
      let dateData = null;
      let startDate, endDate;
      [startDate, endDate] = AlfehrestDate.fromEntity(data);
      this.dateState = this.props.date.overlapsDates(startDate, endDate);
      switch (this.dateState[0]) {
        case DATE_STATES.PAST:
        case DATE_STATES.FUTURE:
          dateData = (
            <p>
              {data.name} {' - '} <Link to={`/${startDate}`}>{startDate.toDefaultFormat()}</Link>
              {' '}
              {'('} <Link to={`/${startDate}/event/${data.id}`}>انتقل إلى زمن هذا الحدث</Link> {')'}
            </p>
          );
          break;
        case DATE_STATES.PRESENT:
          dateData = ( <p>{data.name} {' - '} <Link to={`/${startDate}`}>{startDate.toDefaultFormat()}</Link></p> );
          break;
      }

      return (
        <div>
          <HeadingImage title={data.name} images={data.images} type={this.entityType} date={this.props.date} />
          {dateData}
          <Text text={data.description || 'لم نكتب بعد عن هذا الحدث'} maxLength={120} />
          <Gallery images={data.images} />
          <References references={data.references} />
        </div>
      );
    }
  }
}

Event.defaultProps = defaultProps;
Event.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Event);
