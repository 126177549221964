import defaultState from './defaultState';
import * as actions from './actions';
import { START_YEAR, END_YEAR, ENABLED_ENTITIES } from '../config/index';
import { parseURL, getURL } from '../helpers/links';
import {getFkFromPlural} from '../helpers/entity';
import { buildDateLookup } from '../helpers/date';

function updateSettings(state, key, value) {
  let settings = {...state.settings}
  settings[key] = value;
  return {
    ...state,
    settings
  };
}

function gotoList(state, list) {
  const pageCache = clone(state.pageCache);
  const url = getURL('list', list);
  pageCache[url] = {
    data: list,
    status: 'done',
  };
  return {
    ...state,
    pageCache: pageCache,
    currentPage: url,
    linkList: list,
    isSidePanelOpen: true,
  };
}

function constructYearPage(id, data) {
  const { dateLookup, dataLookup } = data;
  const { rulers, events, states } = dataLookup;
  const targetYear = dateLookup[id];
  const pageCache = {
    year: id,
    states: [],
    events: [],
    status: 'done',
  };
  if (!targetYear) {
    return pageCache;
  }
  if ('states' in targetYear) {
    targetYear.states.map((id) => {
      const state = JSON.parse(JSON.stringify(dataLookup.states[id]));
      let rulerId = null;
      if (targetYear.rulers) {
        rulerId = targetYear.rulers
          .filter(id => state.rulers.indexOf(id) !== -1)
          .pop();
      }
      state.currentRuler = rulerId ? clone(rulers[rulerId]) : undefined;
      pageCache.states.push(state);
    });
  }
  return pageCache;
}

function constructPageCache(entity, id, data) {
  switch (entity) {
    case 'years':
      return constructYearPage(id, data);
  }
}
function gotoDate(state, year, changePage) {
  const yearURL = getURL('years', year);
  /*
  const { dateLookup, dataLookup } = state;

  const pageCache = clone(state.pageCache);

  if (!(year.toHash() in state.pageCache)) {
    pageCache[yearURL] = constructPageCache('years', year.toHash(), {
      dateLookup,
      dataLookup,
    });
  }
  */
  return {
    ...state,
    currentPage: changePage ? yearURL : state.currentPage,
    linkList: []
  };
}

function toggleLightBox(state, value) {
  return {
    ...state,
    isLightBoxOpen: value,
  };
}

function toggleSidePanel(state, value) {
  return {
    ...state,
    isSidePanelOpen: value,
  };
}

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function mapById(arr) {
  const map = {};
  arr.map(entry => (map[entry.id] = entry));
  return map;
}

function seedPageCache(data) {
  const pageCache = {};
  Object.keys(data).forEach((key) => {
    const entries = data[key];
    entries.forEach((entry) => {
      const url = getURL(key, entry.id);
      entry.status = 'not_started';
      pageCache[url] = entry;
    });
  });
  return pageCache;
}

function processInitialData(state, data) {
  const dataLookup = {};
  const dateLookup = buildDateLookup(START_YEAR, END_YEAR, data);
  const listTypes = [
    ['rulers', 'states'],
    ['monuments', 'states'],
    //['monuments', 'cities'],
    ['monuments', 'rulers'],
    ['events', 'states'],
    ['events', 'rulers']
  ];

  Object.keys(data).forEach((key) => {
    const entries = data[key];
    const lookup = {};
    entries.forEach((e) => {
      lookup[e.id] = e;
    });
    dataLookup[key] = lookup;
  });

  const listLookup = {};
  listTypes.forEach((pair) => {
    const [to, from] = pair;
    if(!(from in listLookup)) {
      listLookup[from] = {};
    }
    listLookup[from][to] = {};
    Object.values(dataLookup[from]).forEach((fromEntity) => {
      if(!(fromEntity.id in listLookup[from][to])) {
        listLookup[from][to][fromEntity.id] = [];
      }
    });
    const fromFk = getFkFromPlural(from);
    Object.values(dataLookup[to]).forEach((toEntity) => {
      const a = listLookup[from][to][toEntity[fromFk]];
      if(a) {
        a.push(toEntity);
      }
    });
  });
  Object.freeze(listLookup);
  Object.freeze(dataLookup);
  Object.freeze(dateLookup);

  return {
    ...state,
    dataLookup: dataLookup,
    dateLookup: dateLookup,
    listLookup: listLookup,
    pageCache: seedPageCache(data),
    initialDataStatus: 'done',
  };
}

function handleLoadPageSuccess(state, action) {
  const pageCache = clone(state.pageCache);
  pageCache[action.data.url] = {
    ...pageCache[action.data.url],
    ...action.data.data,
    status: 'done',
  };
  if (state.currentPage !== action.data.url) {
    return {
      ...state,
      pageCache: pageCache,
    };
  }
  return {
    ...state,
    linkList: [],
    pageCache: pageCache,
    currentPage: action.data.url,
    isSidePanelOpen: true
  };
}
function handleLoadPageFailure(state, action) {
  if (state.currentPage !== action.data.url) {
    return state;
  }
  const pageCache = clone(state.pageCache);
  if(!(action.data.url in pageCache)) {
    pageCache[action.data.url] = {};
  }
  pageCache[action.data.url].status = 'failed';
  return {
    ...state,
    pageCache: pageCache,
  };
}

function handleLoadPageRequest(state, action) {
  const pageCache = clone(state.pageCache);
  if(!(action.data.url in pageCache)) {
    pageCache[action.data.url] = {};
  }
  pageCache[action.data.url].status = 'fetching';
  return {
    ...state,
    linkList: [],
    pageCache: pageCache,
    currentPage: action.data.url,
    isSidePanelOpen: true,
  };
}

const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_SETTING:
      return updateSettings(state, action.data.key, action.data.value);

    case actions.GOTO_DATE:
      return gotoDate(state, action.data.date, action.data.shouldChangePage);

    case actions.GOTO_LIST:
      return gotoList(state, action.data);

    case actions.TOGGLE_SIDEPANEL:
      return toggleSidePanel(state, action.data);

    case actions.TOGGLE_LIGHTBOX:
      return toggleLightBox(state, action.data);

    case actions.LOAD_PAGE_REQUEST:
      return handleLoadPageRequest(state, action);

    case actions.LOAD_PAGE_SUCCESS:
      return handleLoadPageSuccess(state, action);

    case actions.LOAD_PAGE_FAILURE:
      return handleLoadPageFailure(state, action);

    case actions.LOAD_DATA_REQUEST:
      if (state.initialDataStatus === 'done') {
        return state;
      }
      return { ...state, initialDataStatus: 'fetching' };

    case actions.LOAD_DATA_SUCCESS:
      return processInitialData(state, action.data);

    case actions.LOAD_DATA_FAILURE:
      return { ...state, initialDataStatus: 'failed' };

    default:
      return state;
  }
};

export default rootReducer;
