import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadPage,toggleSidePanel } from '../store/actions';
import { decodeList, getURL } from '../helpers/links';

const mapStateToProps = state => ({
  pageCache: state.pageCache,
  dataLookup: state.dataLookup,
});

const mapDispatchToProps = {
  loadPage: loadPage,
  toggleSidePanel: toggleSidePanel
};

const propTypes = {
  data: PropTypes.string.isRequired
};
const defaultProps = {};

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.toggleSidePanel(true);
  }

  renderLinks(data) {
    const entities = decodeList(data, this.props.dataLookup);
    const list = [];
    entities.forEach((entity) => {
      const [type, id] = entity;
      const entityData = this.props.dataLookup[type][id];
      const key = entity.join('-');
      list.push(
        <li key={key}>
          <Link to={getURL(type, id, this.props.date)}>{entityData.name}</Link>
        </li>
      );
    });
    return list;
  }



  render() {
    return (
      <ol>
        {this.renderLinks(this.props.data)}
      </ol>
    );
  }
}

List.defaultProps = defaultProps;
List.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
