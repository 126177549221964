
import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const propTypes = {
  images: PropTypes.array
};
const defaultProps = {};

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.onImageClicked = this.onImageClicked.bind(this);
    this.state = {
      isOpen: false,
      index: 0,
      images: this.props.images
    };
  }

  onImageClicked(ev) {
    this.setState({
      index: parseInt(ev.target.dataset.idx, 10),
      isOpen: true
    });
  }

  renderThumbnails() {
    const images = [];
    for(let i=0; i<Math.min(3, this.state.images.length); i++) {
      const img = this.state.images[i];
      images.push(
        <li key={`image_${i}`}>
          <img onClick={this.onImageClicked} data-idx={i} className="thumbnail" src={img.thumbnail_url} alt="" />
        </li>
      );
    }

    return images;
  }

  render() {
    const idx = this.state.index;
    const imgs = this.state.images;
    const isOpen = this.state.isOpen;
    if(imgs.length === 0) {
      return null;
    }

    return (
      <div className="gallery">
        <h4>الصور</h4>
        <ul>
          {this.renderThumbnails()}
          <br clear="all" />
        </ul>
        {isOpen && (
          <Lightbox
            imageTitle={(<p>{imgs[idx].caption}</p>)}
            imageCaption={(<p>{imgs[idx].description}<br />{imgs[idx].credits}</p>)}
            mainSrc={imgs[idx].original_url}
            nextSrc={imgs[(idx + 1) % imgs.length].original_url}
            prevSrc={imgs[(idx + imgs.length - 1) % imgs.length].original_url}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                index: (idx + imgs.length - 1) % imgs.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                index: (idx + 1) % imgs.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

Gallery.defaultProps = defaultProps;
Gallery.propTypes = propTypes;

export default Gallery;
