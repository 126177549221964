import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Switch from "react-switch";
import {Helmet} from "react-helmet";

import { loadPage, updateSetting } from '../store/actions';
import { getURL } from '../helpers/links';
import { getSingular } from '../helpers/entity';

import * as cfg from '../config/index';
import HeadingImage from '../content_components/helpers/HeadingImage';


const mapStateToProps = (state) => ({
  dataLookup: state.dataLookup,
  settings: state.settings
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
  updateSetting: updateSetting
};

const propTypes = {
  id: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};
const defaultProps = {};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(value, ev, id) {
    this.props.updateSetting(id, value);
  }

  renderSettings() {
    const fields = [];
    Object.keys(cfg.SETTINGS).forEach((key) => {
      fields.push(<li key={key}><label><Switch id={key} onChange={this.onChange} className='switch' checked={this.props.settings[key]} />{cfg.SETTINGS[key][0]}</label></li>);
    });
    return fields;
  }
  render() {
    return (
      <div className='settings'>
        <Helmet>
            <title>إعدادات الفهرست</title>
        </Helmet>
        <HeadingImage type="year" title="الإعدادات" images="" />
        <ul>{this.renderSettings()}</ul>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
