const dateToJd = (year, month, day) => {
  if (year < 0) { year++; } // No year zero
  // Jean Meeus algorithm, "Astronomical Algorithms", 1991
  if (month <= 2) {
    year--;
    month += 12;
  }
  return Math.floor(365.25 * (year + 4716)) + Math.floor(30.6001 * (month + 1)) + day - 1524.5;
};

const jdToDate = (jd) => {
  const a = Math.floor(jd + 0.5);
  const b = a + 1524;
  const c = Math.floor((b - 122.1) / 365.25);
  const d = Math.floor(365.25 * c);
  const e = Math.floor((b - d) / 30.6001);
  const month = e - Math.floor(e < 14 ? 1 : 13);
  let year = c - Math.floor(month > 2 ? 4716 : 4715);
  const day = b - d - Math.floor(30.6001 * e);
  if (year <= 0) { year--; } // No year zero
  return [year, month, day-1];
};

const hijriToJulian = (year, month, day, returnJd=true) => {
  const epoch = 1948439.5;
  year = (year <= 0 ? year + 1 : year);
  const jd = day + Math.ceil(29.5 * (month - 1)) + (year - 1) * 354 + Math.floor((3 + (11 * year)) / 30) + epoch - 1;
  if(returnJd) {
    return jd;
  }
  return jdToDate(jd);
};

const julianToHijri = (year, month, day) => {
  const epoch = 1948439.5;
  const jd = Math.floor(dateToJd(year, month, day)) + 0.5;

  let hyear = Math.floor((30 * (jd - epoch) + 10646) / 10631);
  hyear = (hyear <= 0 ? hyear - 1 : hyear);
  const yearStartJd = hijriToJulian(hyear, 1, 1);
  const hmonth = Math.min(12, Math.ceil((jd - 29 - yearStartJd) / 29.5) + 1);
  const monthStartJd = hijriToJulian(hyear, hmonth, 1);
  const hday = jd - monthStartJd + 2;
  return [hyear, hmonth, hday];
};


module.exports = {
  julianToHijri,
  hijriToJulian,
  jdToDate,
  dateToJd
};
