import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadPage } from '../store/actions';
import { getURL } from '../helpers/links';
import { getSingular } from '../helpers/entity';

import * as cfg from '../config/index';
import HeadingImage from '../content_components/helpers/HeadingImage';
import AlfehrestDate from '../helpers/AlFehrestDate';


const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired
};
const defaultProps = {};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.onInput = this.onInput.bind(this);
    this.items = [];
    this.searchList = [];
    this.domInput = null;
  }

  componentDidMount() {
    if (this.domInput) {
      this.domInput.focus();
    }
  }


  onInput(ev) {
    this.setState({
      value: ev.target.value,
    });
  }

  getEntityDate(entity) {
    return entity.a_year ? new AlfehrestDate(entity.a_year, entity.a_month, entity.a_day) : this.props.date;
  }

  getSearchItems() {
    const items = [];
    const entityNames = Object.keys(this.props.dataLookup).filter(
      (k) => ['phases', 'capitals'].indexOf(k) === -1
    );
    entityNames.forEach((key) => {
      const singular = getSingular(key);
      const idList = Object.keys(this.props.dataLookup[key]).filter((id) => this.props.dataLookup[key][id].name);
      items.push(
        ...idList.map((id) => ({
          id: this.props.dataLookup[key][id].id,
          type: singular,
          label: this.props.dataLookup[key][id].name,
          search: this.props.dataLookup[key][id].name.toLowerCase(),
          url: getURL(
            singular,
            this.props.dataLookup[key][id].id,
            this.getEntityDate(this.props.dataLookup[key][id])
          ),
        }))
      );
    });
    return items;
  }

  getError() {
    const v = this.state.value.trim();
    if (v.length === 0) {
      return null;
    }

    if (v.length < cfg.SEARCH_MIN_LENGTH) {
      return 'أدخل ثلاثة أحرف على الأقل';
    }

    if (v.length > cfg.SEARCH_MAX_LENGTH) {
      return 'كلمات البحث أطول مما ينبغي';
    }

    if (this.items.length === 0) {
      return 'لا توجد نتائج';
    }

    return null;
  }

  updateItems() {
    if (this.searchList.length === 0) {
      this.searchList = this.getSearchItems();
    }
    const queryLength = this.state.value.trim().length;
    if (
      queryLength < cfg.SEARCH_MIN_LENGTH ||
      queryLength > cfg.SEARCH_MAX_LENGTH
    ) {
      this.items = [];
      return;
    }

    const exactItems = [];
    const prefixedItems = [];
    const containsItems = [];
    this.searchList.forEach((item) => {
      if (item.label === this.state.value) {
        exactItems.push(item);
        return;
      }
      if (item.search.startsWith(this.state.value.toLowerCase())) {
        prefixedItems.push(item);
        return;
      }
      if (item.search.includes(this.state.value.toLowerCase())) {
        containsItems.push(item);
      }
    });
    this.items = [...exactItems, ...prefixedItems, ...containsItems];
    if (this.items.length > cfg.SEARCH_LIST_MAX) {
      this.items = this.items.slice(0, cfg.SEARCH_LIST_MAX);
    }
  }

  renderItems() {
    if (!this.items || this.items.length === 0) {
      return null;
    }
    const htmlItems = [];
    this.items.forEach((item) => {
      const key = `${item.type}-${item.id}`;
      const className = `search-item ${item.type}`;
      htmlItems.push(
        <li key={key} className={className}>
          <Link to={item.url}>{item.label}</Link>
        </li>
      );
    });
    return <ul className="search-list">{htmlItems}</ul>;
  }

  renderError() {
    const error = this.getError();
    if (error) {
      return (
        <div className="search-error">
          <span>{error}</span>
        </div>
      );
    }
  }

  render() {
    this.updateItems();
    return (
      <div>
        <HeadingImage type="year" title="بحث" images="" />
        <div className="search">
          <input
            placeholder="أدخل كلمات البحث"
            className="search-input"
            maxLength={cfg.SEARCH_MAX_LENGTH}
            value={this.state.value}
            onInput={this.onInput}
            ref={(input) => {
              this.domInput = input;
            }}
          />
          {this.renderError()}
          {this.renderItems()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
