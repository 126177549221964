import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getEnv, isOnServer } from './helpers/env';
import { parseURL } from './helpers/links';
import StateLayer from './map_components/StateLayer';
import AlfehrestDate from './helpers/AlFehrestDate';
import IconLayer from './map_components/IconLayer';

const propTypes = {
  date: PropTypes.instanceOf(AlfehrestDate),
  locked: PropTypes.bool,
};
const defaultProps = {
  date: null,
  locked: false,
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.layers = {
      event: null,
      monument: null,
      state: null,
      city: null,
    };

    this.state = {
      ready: false,
    };
    this.onMapLoaded = this.onMapLoaded.bind(this);
    this.onMapClicked = this.onMapClicked.bind(this);
  }

  componentDidMount() {
    if(isOnServer()) {
      return;
    }
    const mapboxgl = require('mapbox-gl');

    mapboxgl.accessToken = getEnv("MAPBOX_TOKEN");
    if(!window['rtl_plugin_loaded']) {
      mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js');
      window['rtl_plugin_loaded'] = true;
    }
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/drdrsh/cjsrs0mns0d7m1fryvbq4lpzd',
      zoom: 3,
      center: [45.0, 20.0],
      attributionControl: false,
      navigationControl: false
    });
    this.map.on('load', this.onMapLoaded);
    this.map.on('click', this.onMapClicked);
    this.map.addControl(new mapboxgl.AttributionControl(), 'bottom-left');
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');
  }

  componentWillUnmount() {
    if(isOnServer()) {
      return;
    }
    this.map.off('load', this.onMapLoaded);
    this.map.off('click', this.onMapClicked);
  }

  onMapLoaded() {
    this.setState({ ready: true });
  }

  onMapClicked(ev) {
    const firstFeature = this.map.queryRenderedFeatures(ev.point)[0];
    const keys = ['icon', 'state']
    for (let i = 0; i < keys.length; i += 1) {
      // Did we get a click before layers are loaded?
      if(!this.layers[keys[i]]) {
        // Ignore it for now.
        continue;
      }
      const layers = this.layers[keys[i]].queryLayers();
      if (firstFeature && firstFeature.layer && layers.indexOf(firstFeature.layer.id) !== -1) {
        this.layers[keys[i]].onLayerClicked(ev, firstFeature);
        return;
      }
    }
  }

  toggleMapLock(state) {
    const handlers = [
      'dragRotate',
      'dragPan',
      'scrollZoom',
      'touchZoomRotate',
      'keyboard',
    ];
    if (!this.state.ready) {
      return;
    }
    const funcName = state ? 'disable' : 'enable';
    this.map.dragRotate[funcName]();
    this.map.dragPan[funcName]();
    this.map.scrollZoom[funcName]();
    this.map.touchZoomRotate[funcName]();
    this.map.keyboard[funcName]();
  }

  render() {
    let date = this.props.date || new AlfehrestDate(Number(this.props.match.params.date));
    const map = this.map;
    this.toggleMapLock(this.props.locked);
    const parts = parseURL(this.props.location.pathname);
    console.log(parts);
    return (
      <div id="map">
        {this.state.ready &&
          map && (
            <div>
              <StateLayer
                ref={(c) => {
                  c && (this.layers.state = c.getWrappedInstance());
                }}
                selectedEntity={
                  parts.entity && parts.entity === 'states' ? parts.id : null
                }
                offset={this.props.locked ? 0 : -100}
                map={map}
                date={date}
              />
              <IconLayer
                ref={(c) => {
                  c && (this.layers.icon = c.getWrappedInstance());
                }}
                selectedEntity={parts}
                offset={this.props.locked ? 0 : -150}
                speed={this.props.locked ? 150 : 1.2}
                zoom={5}
                map={map}
                date={date}
              />
            </div>
          )}
      </div>
    );
  }
}

Map.defaultProps = defaultProps;
Map.propTypes = propTypes;

export default withRouter(Map);
