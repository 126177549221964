import React from 'react';
import { connect } from 'react-redux';

import {shouldInlineMap} from './helpers/layout';
import Map from './Map';
import Overlay from './Overlay';
import Sidepanel from './Sidepanel';

const mapStateToProps = (state, ownProps) => ({
  isSidePanelOpen: state.isSidePanelOpen
});
const mapDispatchToProps = {};
class App extends React.Component {
  render() {
    const hideMap = shouldInlineMap() && this.props.isSidePanelOpen;
    return (
      <div>
        <Overlay />
        {!hideMap && <Map />}
        <Sidepanel />
        
        <div className="play-store-button">
          <a href="https://play.google.com/store/apps/details?id=org.alfehrest.app&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img alt='احصل عليه من Google Play' src="https://play.google.com/intl/en_us/badges/static/images/badges/ar_badge_web_generic.png"/>
          </a>
        </div>
      </div>
    );
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
