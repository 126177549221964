import * as DATE_STATES from './date_states';
import AlfehrestDate from './AlFehrestDate';

export const getEntityDateState = () => {};

export const getYearString = (date) => {
  if(!date) {
    return "";
  }
  return date.toStringFormat("%B %Y");
};

export const yearInRange = (start, end, year) => {
  return year >= start && year <= end;
};

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/*
export const createGraph = (data) => {
    const graph = {};
    const entities = {};
    Object.keys(data).forEach((key) => {
        const entries = data[key];
        entities[key] = {};
        entries.forEach((entry) => {
            entities[key][entry.id] = entry;
        });
    });

    Object.keys(data).forEach((key) => {
        const entries = data[key];
        entries.forEach((entry) => {
            const foreign = Object.keys(entry).fliter(x => x.endsWith('_id'));

        for(let i=s; i<e; i+=1) {
            if(!(i in yearLookup)) {
                yearLookup[i] = {};
            }
            if(!(key in yearLookup[i])) {
                yearLookup[i][key] = [];
            }
            yearLookup[i][key].push(entry.id);
        }
      });
    });
    console.log(yearLookup);
    return yearLookup;


}
*/
function getEntityByYear(entity, id, data, date) {
  const s = data.states[id];
  const stateEntityList = s[entity];
  const entityData = data[entity];
  for (let i = 0; i < stateEntityList.length; i += 1) {
    const entityElement = entityData[stateEntityList[i]];
    const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(entityElement);
    const dateState = date.overlapsDates(startDate, endDate);
    if (dateState[0] == DATE_STATES.PRESENT) {
      return entityElement;
    }
  }
  return null;
}

export const getCapitalByYear = (id, data, year) => {
  return getEntityByYear('capitals', id, data, year);
};

export const getRulerByYear = (id, data, year) => {
  return getEntityByYear('rulers', id, data, year);
};

export const getStatesByYear = (states, year) => {
  const activeStates = [];
  const keys = Object.keys(states);
  keys.forEach((k) => {
    const s = clone(states[k]);
    if (!yearInRange(s.start_year, s.end_year, year)) {
      return;
    }
    activeStates.push(s);
    for (let i = 0; i < s.rulers.length; i++) {
      const start = s.rulers[i].start_year;
      const end = s.rulers[i].end_year;
      if (yearInRange(start, end, year)) {
        s.currentRuler = s.rulers[i];
        break;
      }
    }
    if (!s.currentRuler) {
      s.currentRuler = null;
    }
    for (let i = 0; i < s.phases.length; i++) {
      const start = s.phases[i].start_year;
      const end = s.phases[i].end_year;
      if (yearInRange(start, end, year)) {
        s.currentPhase = s.phases[i];
        break;
      }
    }
  });
  return activeStates;
};

function getStartEnd(entityType, entity, s, e) {
  return [
    entity.a_year ? new AlfehrestDate(entity.a_year, entity.a_month, entity.a_day).toHash() : s,
    entity.z_year ? new AlfehrestDate(entity.z_year, entity.z_month, entity.z_day).toHash() : e
  ];
}

export function buildDateLookup(start, end, data) {
  const dateLookup = {};
  Object.keys(data).forEach((key) => {
    const entries = data[key];
    entries.forEach((entry) => {
      const [s, e] = getStartEnd(key, entry, start, end);
      for (let i = s; i < e; i += 1) {
        if (!(i in dateLookup)) {
          dateLookup[i] = {};
        }
        if (!(key in dateLookup[i])) {
          dateLookup[i][key] = [];
        }
        dateLookup[i][key].push(entry.id);
      }
    });
  });
  return dateLookup;
}
