import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {getEntityDateState} from '../helpers/date';
import { loadPage } from '../store/actions';

import AbstractEntityComponent from './AbstractEntityComponent';
import HeadingImage from './helpers/HeadingImage';
import Gallery from './helpers/Gallery';
import References from './helpers/References';
import Text from './helpers/Text';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
  pageCache: state.pageCache,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
};
const defaultProps = {};

class Monument extends AbstractEntityComponent {
  constructor(props) {
    super(props);
  }

  getEntityType() {
    return 'monument';
  }

  renderRuler(ruler) {
    if (!ruler) {
      return null;
    }
    return (
      <span>
        {' '}built by{' '}
        <Link to={`/${ruler.start_year}/rulers/${ruler.id}`}>{ruler.name}</Link>
      </span>
    );
  }

  renderCity(city) {
    if (!city) {
      return null;
    }
    return (
      <span>
        {' '} located near {' '}
        <Link to={`/${this.props.year}/cities/${city.id}`}>{city.name}</Link>
      </span>
    );
  }

  renderState(state) {
    if (!state) {
      return null;
    }
    return (
      <span>
        {' '} is one of {' '}
        <Link to={`/${state.start_year}/states/${state.id}`}>{state.name}</Link>
        {' '} monuments {' '}
      </span>
    );
  }

  // TODO: Render Empty container when state is out of history
  renderEntity(data) {
    if (data.status === 'done') {
      let dateData = null;

      const ruler = this.props.dataLookup.rulers[data.ruler_id];
      const city = this.props.dataLookup.cities[data.city_id];
      const state = ruler ? this.props.dataLookup.states[ruler.state_id] : null;

      this.dateState = getEntityDateState(
        state,
        this.props.year
      );

      dateData = (
        <p>
          {data.name}
          {this.renderState(state)}
          {this.renderRuler(ruler)}
          {this.renderCity(city)}
        </p>
      );
      return (
        <div>
          <HeadingImage title={data.name} images={data.images} type={this.entityType} date={this.props.date} />
          {dateData}
          <Text text={data.description} maxLength={120} />
          <Gallery images={data.images} />
          <References references={data.links} />
        </div>
      );
    }
  }
}

Monument.defaultProps = defaultProps;
Monument.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Monument);
