import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { getEnv, isProd } from './helpers/env';
import './index.css';
import {Root} from './Root';

if(typeof Array.prototype.flatMap !== 'function') {
  const concat = (x,y) => x.concat(y);
  const flatMap = (f,xs) => xs.map(f).reduce(concat, []);
  Array.prototype.flatMap = function(f) { return flatMap(f, this) };
}

const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
const concat = Function.bind.call(Function.call, Array.prototype.concat);
const keys = Reflect.ownKeys;

if (!Object.values) {
	Object.values = function values(O) {
		return reduce(keys(O), (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []), []);
	};
}

if (!Object.entries) {
	Object.entries = function entries(O) {
		return reduce(keys(O), (e, k) => concat(e, typeof k === 'string' && isEnumerable(O, k) ? [[k, O[k]]] : []), []);
	};
}

if(isProd()) {
	Sentry.init({release: getEnv('RELEASE'), dsn: getEnv('SENTRY_DSN')});
}

ReactDOM.render(<Root />, document.getElementById('root'));
