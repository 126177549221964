import { createBrowserHistory } from 'history';
import { isOnServer } from './helpers/env';

export default (function() {
  if(isOnServer()) {
    return undefined;
  }
  return createBrowserHistory();
})();

