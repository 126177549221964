import {fixNumerals} from '../helpers/lang';
import * as DATE_STATES from './date_states';

import { julianToHijri, hijriToJulian, jdToDate, dateToJd } from '../shared/calendars'

const MIN_YEAR = 0;
const MAX_YEAR = 2000;

const MONTH_LOOKUP = {
  'h': ['محرم', 'صفر', 'ربيع الأول', 'ربيع الثاني', 'جمادى الأولى', 'جمادى الآخرة', 'رجب', 'شعبان', 'رمضان', 'شوال', 'ذو القعدة', 'ذو الحجة'],
  'j': ['يناير','فبراير','مارس','أبريل','مايو','يونيو','يوليو','أغسطس','سبتمبر','أكتوبر','نوفمبر','ديسمبر']
}

const CALENDAR_LOOKUP = {
  'h': 'هـ',
  'j': 'م'
}
export default class AlfehrestDate {
  static fromEntity(entity) {
    return [
      new AlfehrestDate(entity.year_start, entity.month_start, entity.day_start, 'j'),
      new AlfehrestDate(entity.year_end, entity.month_end, entity.day_end, 'j')
    ];
  }

  static fromEntityAtoZ(entity) {
    return [
      new AlfehrestDate(entity.a_year, entity.a_month, entity.a_day, 'j'),
      new AlfehrestDate(entity.z_year, entity.z_month, entity.z_day, 'j')
    ];
  }

  constructor(year, month, day, cal='j') {
    if(arguments.length === 1) {
      [year, month, day] = jdToDate(year);
      cal = 'j';
    }
    this.year = year;
    this.month = month || 1;
    this.day = day || 1;
    this.yearCertainity = true;
    this.monthCertainity = !!month;
    this.dayCertainity = !!day;

    this.cal = cal;
    if(['h', 'j'].indexOf(this.cal) === -1) {
      throw `Calendar type ${cal} is not implemented!`
    }
  }

  overlapsDates(startDate, endDate) {
    if(this.getYear() < (startDate.getYear() || MIN_YEAR)) {
      return [DATE_STATES.FUTURE, startDate.getYear() - this.getYear()];
    }

    if(this.getYear() > (endDate.getYear() || MAX_YEAR)) {
      return [DATE_STATES.PAST, this.getYear() - endDate.getYear()];
    }

    return [DATE_STATES.PRESENT, this.getYear() - startDate.getYear()];
  }

  equals(leftSide) {
    return this.toJD() === leftSide.toJD();
  }

  timeUntil(otherDate) {
    const y = otherDate.year - this.year;

    let m = null;
    let d = null;
    if(this.monthCertainity && otherDate.monthCertainity) {
      m = otherDate.month - this.month;
    }
    if(this.dayCertainity && otherDate.dayCertainity) {
      d = otherDate.day - this.day;
    }
    return [y, m, d];
  }

  timeUntilString(otherDate) {
    const [y, m, d] = this.timeUntil(otherDate);

    const yStr = arabizeNumeric(y, 'f', 'سنة', 'سنتان', 'سنوات');
    const mStr = arabizeNumeric(m, 'm', 'شهر', 'شهران', 'شهور');
    const dStr = arabizeNumeric(d, 'm', 'يوم', 'يومان', 'أيام');
    return [yStr, mStr, dStr].filter(s => s !== null).join(" و");

    function arabizeNumeric(count, g, singular, double, plural) {
      const wordNumerals = {
        'm': ['ثلاث', 'أربع', 'خمس', 'ست', 'سبع', 'ثمان', 'تسع','عشر'],
        'f': ['ثلاثة', 'أربعة', 'خمسة', 'ستة', 'سبعة', 'ثمانية', 'تسعة','عشرة']
      };
      if(count == 0) {
        return null;
      }
      if(count == 1) {
        return singular;
      }
      if(count == 2) {
        return double;
      }
      if(count > 2 && count < 11) {
        const n = wordNumerals[g == 'm' ? 'f' : 'm'][count - 3];
        return `${n} ${plural}`;
      }
      if(count > 11) {
        return `${count} ${singular}`;
      }
      return null;
    }

  }
  overlapsEntity(entity) {
    return this.overlapsDates(
      new AlfehrestDate(entity.year_start, entity.month_start, entity.day_start, 'j'),
      new AlfehrestDate(entity.year_end, entity.month_end, entity.day_end, 'j')
    );
  }

  getCertainity() { return [this.yearCertainity, this.monthCertainity, this.dayCertainity]; }

  getDay() { return Number(this.day); }
  getMonth() { return Number(this.month); }
  getYear() { return Number(this.year); }

  toArray() { return [this.year, this.month, this.day]; }

  toHash() { return (this.getYear() * 12) + this.month; }

  toString() { return "" + this.toJD(); }

  toDefaultFormat() {
    let format = "%Y %c";
    if(this.monthCertainity) {
      if(this.dayCertainity) {
        format = "%d %B %Y %c";
      } else {
        format = "%B %Y %c";
      }
    }
    return `${this.toHijri().toStringFormat(format)} - ${this.toStringFormat(format)}`;
  }

  toStringFormat(format) {
    const dict = {
      '%Y': this.year,
      '%B': MONTH_LOOKUP[this.cal][this.month - 1],
      '%m': this.month,
      '%d': this.day,
      '%c': CALENDAR_LOOKUP[this.cal]
    };

    let output = format;
    Object.entries(dict).forEach(([key, value]) => {
      output = output.replace(key, value);
    });
    const store = require('../Root').store
    if(store && !store.getState().settings.arabic_numerals) {
      return fixNumerals(output, 'h');
    }
    return output;
  }

  toHijri() {
    if(this.cal === 'h') {
      return new AlfehrestDate(this.year, this.month, this.day, 'h');
    }
    if(this.cal === 'j') {
      const ret = julianToHijri(this.year, this.month, this.day);
      return new AlfehrestDate(ret[0], ret[1], ret[2], 'h');
    }
  }

  toJulian() {
    if(this.cal === 'j') {
      return new AlfehrestDate(this.year, this.month, this.day, 'j');
    }
    if(this.cal === 'h') {
      const ret = hijriToJulian(this.year, this.month, this.day);
      return new AlfehrestDate(ret[0], ret[1], ret[2], 'h');
    }
  }

  toJD() {
    if(this.cal === 'j') {
      return dateToJd(this.year, this.month, this.day)
    }
    if(this.cal === 'h') {
      return this.toJulian().toJD();
    }
  }
}
