import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AbstractEntityComponent from './AbstractEntityComponent';
import Gallery from './helpers/Gallery';
import HeadingImage from './helpers/HeadingImage';
import References from './helpers/References';
import Publications from './helpers/Publications';
import Text from './helpers/Text';

import { getYearString, getEntityDateState,} from '../helpers/date';
import * as DATE_STATES from '../helpers/date_states';
import { loadPage } from '../store/actions';
import { PRESENT } from '../helpers/date_states';
import AlfehrestDate from '../helpers/AlFehrestDate';
import { getURL } from '../helpers/links';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup,
  listLookup: state.listLookup,
  pageCache: state.pageCache,
});

// Maps component props to action creators
const mapDispatchToProps = {
  loadPage: loadPage,
};

const propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(AlfehrestDate).isRequired,
};
const defaultProps = {};

const tagLookup =  {
  'فلك' 		: 'astro',
  'رياضيات' : 'math',
  'طب' 		  : 'medicine',
  'فيزياء' 	: 'physics',
  'كيمياء' 	: 'chemistry',
  'فقه' 		: 'law',
  'أحياء' 	: 'biology',
  'فلسفة' 	: 'philosophy',
  'موسيقى' 	: 'music',
  'تاريخ' 	: 'history',
  'جغرافيا' : 'geography',
  'لغة' 		: 'lang',
  'ترجمة' 	: 'trans',
  'حديث' 		: 'hadith',
  'تفسير' 	: 'tafseer',
  'قرآن'  	: 'tafseer',
  'هندسة' 	: 'geometry',
  'أدب' 		: 'lit',
  'اجتماع' 	: 'soc',
  'اقتصاد' 	: 'eco',
  'ميكانيكا': 'mecha',
  'صيدلة' 	: 'pharma',
  'نبات' 		: 'botany'
};

class Scholar extends AbstractEntityComponent {
  constructor(props) {
    super(props);
  }

  getEntityType() {
    return 'scholar';
  }

  renderTags(tagsData) {
    const tags = JSON.parse(tagsData);
    return <ul className="tags">
      {tags.map((tag) => <li key={tag} data-label={tag} className={tagLookup[tag]}></li>)}
    </ul>;
  }

  getBurialData(data) {
    if(data.burial_place_id === null) {
      return "";
    }
    const place = this.props.dataLookup.places[data.burial_place_id];
    return (
      <p>
        {' مكان دفنه '}
        <Link to={getURL("places", place.id, this.props.date)}>{place.name}</Link>
      </p>
    )
  }

  // TODO: Render Empty container when state is out of history
  renderEntity(data) {
    if (data.status === 'done') {
      const tagDom = this.renderTags(data.tags);
      let dateData = null;
      let startDate, endDate;
      [startDate, endDate] = AlfehrestDate.fromEntity(data);
      this.dateState = this.props.date.overlapsDates(startDate, endDate);
      switch (this.dateState[0]) {
        case DATE_STATES.PAST:
        case DATE_STATES.FUTURE:
          dateData = (
            <p>
              ولد في
              {' '}
              <Link to={`/${startDate}`}>{startDate.toDefaultFormat()}</Link>
              <br />
              توفي في
              {' '}
              <Link to={`/${endDate}`}>{endDate.toDefaultFormat()}</Link>
              <br />
            </p>
          );
          break;
        case DATE_STATES.PRESENT:
          const [scholarPlace, place] = data.places.filter((id) => {
            const [startDate, endDate] = AlfehrestDate.fromEntityAtoZ(this.props.dataLookup.scholar_places[id]);
            const dateState = this.props.date.overlapsDates(startDate, endDate);
            return dateState[0] === PRESENT;
          }).flatMap((id) => [
            this.props.dataLookup.scholar_places[id],
            this.props.dataLookup.places[this.props.dataLookup.scholar_places[id].place_id]
          ]);
          const url = getURL("places", place.id, this.props.date);
          dateData = (
            <p>
              ولد في
              {' '}
              <Link to={`/${startDate}`}>{startDate.toDefaultFormat()}</Link>
              <br />
              توفي في
              {' '}
              <Link to={`/${endDate}`}>{endDate.toDefaultFormat()}</Link>
              <br />
              {' ('}
              عمره في هذا الوقت
              {' '}
              {startDate.timeUntilString(this.props.date)}
              { ')' }
              <br />
              {' ('}
              مكان تواجده التقريبي في هذا الوقت
              {' '}
              <Link to={url}>{place.name}</Link>
              { ')' }
            </p>
          );
          break;
      }

      return (
        <div>
          <HeadingImage title={data.name} images={data.images} type={this.entityType} date={this.props.date} />
          {tagDom}
          {dateData}
          {this.getBurialData(data)}
          <Text text={data.description || 'لم نكتب بعد عن هذا العالم'} maxLength={120} />
          <Gallery images={data.images} />
          <Publications publications={data.publications} />
          <References references={data.references} />
        </div>
      );
    }
  }
}

Scholar.defaultProps = defaultProps;
Scholar.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scholar);
