import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getURL } from '../helpers/links';
import HeadingImage from '../content_components/helpers/HeadingImage';

const mapStateToProps = (state) => ({
  initialDataStatus: state.initialDataStatus,
  dataLookup: state.dataLookup
});

// Maps component props to action creators
const mapDispatchToProps = {
};

const propTypes = {
  year: PropTypes.number.isRequired
};
const defaultProps = {};

class Cities extends React.Component {


  render() {
    if (this.props.initialDataStatus !== 'done') {
      return null;
    }
    const listOfCities = Object.keys(this.props.dataLookup.cities).map((key) => {
      const city = this.props.dataLookup.cities[key];
      return (
        <li key={`city-${city.id}`}>
          <Link to={getURL('city', city.id, this.props.year)}>{city.name}</Link>
        </li>
      );
    });
    return (
      <div>
        <HeadingImage type="all-cities" title="All Cities" images="" /> 
        <ol>{listOfCities}</ol>
      </div>
    );
  }
}

Cities.defaultProps = defaultProps;
Cities.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cities);
