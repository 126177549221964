import {SETTINGS} from '../config/index';
import AlfehrestDate from '../helpers/AlFehrestDate';
// This is simply the default state of the app

let settings = {};
Object.keys(SETTINGS).forEach((key) => {
  settings[key] = SETTINGS[key][1];
});

const defaultState = {
  dataLookup: {},
  dateLookup: {},

  initialDataStatus: 'not_started',

  currentDate: new AlfehrestDate(825, 1, 1),
  currentPage: '',

  pageCache: {},
  linkList : [],

  isLoading: false,
  isSidePanelOpen: false,
  settings: settings
};

export default defaultState;
