import React from 'react';
import { Link } from 'react-router-dom';

const SHORTHAND_LOOKUP = {
  state: 's',
  scholar: 'x',
  place: 'p',
  event: 'e',
  monument: 'm',
  ruler: 'r',
};

const REV_SHORTHAND_LOOKUP = {
  m: 'monuments',
  s: 'states',
  x: 'scholars',
  e: 'events',
  p: 'places',
  r: 'rulers',
};

const getYearURL = (date) => `/years/${date}`;
const getMonumentURL = (id) => `/monuments/${id}`;
const getRulerURL = (id) => `/rulers/${id}`;
const getScholarURL = (id) => `/scholars/${id}`;
const getPlaceURL = (id) => `/places/${id}`;
const getEventURL = (id) => `/events/${id}`;
const getStateURL = (id) => `/states/${id}`;
const getCityURL = (id) => `/cities/${id}`;

/*
* This is a VERY VERY dangerous method because
* it takes stuff from the url and embeds it in
* the DOM. I need to be exteremly careful not to
* let bad requests through
# TODO: make sure list items are unique
# TODO: make sure list items exists
# TODO: make sure list is not longer than it should
*
*/
export const decodeList = (list, dataLookup) => {
  if (list.length > 100) {
    return [];
  }
  const parts = list.split(':');
  const output = [];
  parts.forEach((part) => {
    const key = part[0];
    if (key in REV_SHORTHAND_LOOKUP) {
      const subparts = part.substring(1).split(',');
      subparts.forEach((i) => {
        const type = REV_SHORTHAND_LOOKUP[key];
        const id = parseInt(i, 10);
        if (
          type in dataLookup &&
          id in dataLookup[type]
        ) {
          output.push([type, id]);
        }
      });
    }
  });
  return output;
};

const getListURL = (entities) => {
  const compressionTable = {};
  entities.forEach((val) => {
    const [entity, id] = val;
    if (entity in SHORTHAND_LOOKUP) {
      const key = SHORTHAND_LOOKUP[entity];
      if (!(key in compressionTable)) {
        compressionTable[key] = [];
      }
      compressionTable[key].push(id);
    }
  });

  const fragments = [];
  Object.keys(compressionTable).forEach((key) => {
    const str = compressionTable[key].join(',');
    fragments.push(`${key}${str}`);
  });
  return `/list/${fragments.join(':')}`;
};

export const getEntityFromUrl = (url, data) => {
  const { entity, id } = parseURL(url);
  return data[entity][id];
};
export const parseURL = (url) => {
  const parts = url.split('/');
  switch (parts.length) {
    case 2:
      return { year: parts[1] };
    case 3:
      return {
        year: parts[1],
        page: parts[2],
      };
    case 4:
      return {
        id: parts[3],
        year: parts[1],
        entity: parts[2],
      };
  }
};

export const getLink = (entity, data, year) => {
  if (!data) {
    return <span>Unknown</span>;
  }
  const url = getURL(entity, data.id, year);
  return <Link cssClass={entity} to={url}>{data.name}</Link>;
};


export const getURL = (entity, id, date) => {
  switch (entity) {
    case 'list':
      return (date ? `/${date}` : '') + getListURL(id);

      case 'year':
      case 'years':
        return getYearURL(id);

    case 'city':
    case 'cities':
      return (date ? `/${date}` : '') + getCityURL(id);

    case 'ruler':
    case 'rulers':
      return (date ? `/${date}` : '') + getRulerURL(id);

    case 'scholar':
    case 'scholars':
      return (date ? `/${date}` : '') + getScholarURL(id);

    case 'event':
    case 'events':
      return (date ? `/${date}` : '') + getEventURL(id);

    case 'place':
    case 'places':
      return (date ? `/${date}` : '') + getPlaceURL(id);

    case 'state':
    case 'states':
      return (date ? `/${date}` : '') + getStateURL(id);

    case 'monument':
    case 'monuments':
      return (date ? `/${date}` : '') + getMonumentURL(id);
  }
  return null;
};

export function getLoadingStatus(url, pageCache) {
  if (url.indexOf('/') === -1) {
    return 'done';
  }
  return pageCache[url].status;
}
